@import '@styles/styles.scss';

.ItemPayMethodOption {
    width: 68px;
    height: 68px;
    border: none;
    background: center / contain no-repeat
        url('../../../assets/images/iconGeneral/circle-bk.svg');

    .payMethodName {
        display: none;
    }
}
