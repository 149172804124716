@import '@styles/styles.scss';

.wrapper {
    &.sellPaying,
    &.buyPaying {
        .infosGroupWrapper {
            display: flex;
            flex-direction: column-reverse;
        }
    }
}
