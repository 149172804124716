@import '@styles/styles.scss';

.orderInfos {
    background-color: $infosBkColor;
    padding: 20px;
    border-radius: 20px;

    .infosTitle {
        font-size: $FONTSIZE_24;
        font-weight: bold;
        margin-bottom: 24px;
        position: relative;

        @include headerHightLightLeft();
    }

    .infosWrapper {
        .buyUnit,
        .sellUnit {
            .fieldLabel {
                font-size: $FONTSIZE_18;
            }

            .field {
                font-weight: bold;
                font-size: $FONTSIZE_30;
                color: $COLOR_RED;
            }
        }
    }

    @include mobile {
        padding: 25px 15px;
    }
}
