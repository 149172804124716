@import '@styles/styles.scss';

.wrapper {
    &.buyPaying,
    &.sellPaying {
        padding-top: 0;
    }
}

.mainInfosWrapper {
    min-width: 600px;

    @include tablet {
        min-width: auto;
    }
}

.resultedWrapper {
    .mainInfosWrapper {
        background-color: $infosBkColor;
        border-radius: 20px;
    }
}

/*=============================================
=                 Buttons                     =
=============================================*/

.btnGroupWrapper {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0 20px;

    @include tablet {
        padding: 20px 0;
    }

    @include mobile {
        .btn:only-child {
            margin-left: auto;
        }

        justify-content: space-between;

        &:after {
            display: none;
        }
    }

    @include mobileLargeAbove {
        bottom: 0;
    }
}

.btn {
    &.MuiButton-contained {
        svg .fillArea {
            fill: $primaryTextContrast;
        }

        &.Mui-disabled {
            background-color: $primaryColor;
            color: rgba($primaryTextContrast, 0.5);
            opacity: 0.6;

            .MuiButton-label {
                color: $primaryTextContrast;
            }
        }
    }

    &.MuiButton-outlined {
        color: $COLOR_STEELGREY;
        background-color: transparent;
        box-shadow: none;
        border: none;

        &:hover {
            border: none;
            box-shadow: none;
            text-decoration: none;
            background-color: rgba($COLOR_BLACK, 0.04);
        }

        .MuiButton-label {
            color: $COLOR_STEELGREY;
        }
    }

    .text {
        font-weight: bold;
    }
    .content .iconAddition {
        display: none;
    }
}

/*=====  End of Buttons  ======*/
.itemField.count,
.itemField.userPrice {
    display: none;
}
.itemField {
    &.unitCount,
    &.realAmount {
        .fieldLabelWrapper {
            font-weight: bold;
        }
        .unit,
        .numeric {
            color: $secondaryColor;
            background: -webkit-linear-gradient(
                89deg,
                #80d8ff 0%,
                #ea80fc 100%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            line-height: 1;
        }
    }
}

.qrBoardInfosWrapper {
    .qrCodeImg {
        width: 250px;
        height: 250px;
        padding: 30px;
    }

    @include mobile {
        .qrCodeImg {
            padding: 10px;
            width: 125px;
            height: 125px;
        }
    }
}

.snackbarWrapper {
    &.MuiAlert-standardSuccess {
        background-color: $primaryColor;
        color: $COLOR_WHITE;
        box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.18);
    }

    .MuiAlert-icon {
        display: none;
    }
}
