@import '@styles/styles.scss';

.stepper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    background: transparent
        radial-gradient(closest-side at 50% 50%, #484747 0%, $primaryColor 100%)
        0% 0% no-repeat padding-box;
    min-width: 40%;
    color: $primaryTextContrast;
    border-radius: 20px 0px 0px 20px;
    position: relative;

    .logoBrand {
        width: 116px;
        height: 116px;
        position: absolute;
        border: none;
        background: center / contain no-repeat
            url('../../assets/images/iconGeneral/circle-bk.svg');
        bottom: 50%;

        @include mobileLarge {
            width: 88px;
            height: 88px;
        }
    }
    .stepperContent {
        position: relative;
        margin-bottom: 20px;
        @include headerHighlightBottom();

        &:after {
            bottom: 0;
        }

        .stepName {
            font-size: $FONTSIZE_24;
            margin-bottom: 13px;

            @include mobileLarge {
                font-size: $FONTSIZE_20;
            }

            @include mobile {
                font-size: $FONTSIZE_16;
            }
        }
    }

    @include mobileLarge {
        width: 100%;
        height: 230px;
        border-radius: 10px 10px 0px 0px;
    }

    @include mobile {
        .stepIndex {
            font-size: $FONTSIZE_14;
        }
    }
}
