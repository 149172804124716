@import '@styles/styles.scss';

.qrCodeBoardWrapper {
    background: center / contain no-repeat
        url('../../../assets/images/iconGeneral/circle-bk.svg');
    border: none;
    padding: 30px;
    .qrCodeImg {
        padding: 10px;
        width: 125px;
        height: 125px;
    }
}
