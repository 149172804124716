@import '@styles/styles.scss';

.itemField {
    &.sellerName {
        .fieldLabelWrapper,
        .fieldValue {
            align-items: flex-start;
            margin-bottom: 8px;
        }
    }
}
