@import '@styles/styles.scss';

button.btn.btnResend {
    background: $primaryColor;
    border: 1px solid $primaryColor;

    &.MuiButton-contained.Mui-disabled,
    &:hover {
        background: $primaryColor;
        border: 1px solid $primaryColor;
    }
    &:disabled {
        opacity: 0.8;
    }

    .countDownSecondTxt,
    .text {
        color: $secondaryColor;
    }
}
