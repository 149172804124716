@import '@styles/styles.scss';

.infosHeader {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 20px 0;
    margin-bottom: 20px;
    text-align: center;

    @include mobileLarge {
        padding: 40px 15px 0;
        margin-bottom: 0;
    }

    &:after {
        content: '';
        width: 100%;
        height: 1px;
        @include secondaryGradientHorizontalBk();
        margin: 40px 30px 10px;

        @include mobileLarge {
            margin: 30px 30px 0;
        }
    }

    .headline {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 15px;

        .title {
            position: relative;
            font-size: $FONTSIZE_40;
            margin-top: 20px;
            margin-bottom: 15px;
            @include headerHighlightBottom();
        }

        .tickIco,
        .processIco,
        .waitingIco,
        .cancelIco {
            width: 80px;
            height: 80px;
            .fillArea {
                fill: $secondaryColor;
            }
        }
    }

    .message {
        font-size: $FONTSIZE_24;
    }

    @include mobile {
        .headline {
            .title {
                font-size: $FONTSIZE_30;
            }

            .iconHead {
                width: 50px;
                height: 50px;
            }
        }

        .message {
            font-size: $FONTSIZE_16;
        }
    }
}
