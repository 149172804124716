@import '@styles/styles.scss';

.itemTag {
    font-size: $FONTSIZE_20;

    button {
        padding: 5px 10px;
        border-radius: 32px;
        background-color: $primaryColor;
        .iconTick {
            width: 15px;
            height: 17px;
        }
    }
}
