@import '@styles/styles.scss';

.actionsDialogContent {
    .headline {
        position: relative;
        .iconTitle {
            width: 80px;
            height: 80px;
            .fillArea {
                fill: $secondaryColor;
            }
        }

        .title {
            margin-bottom: 15px;
            @include headerHighlightBottom();
        }
    }
    .dialogActionGroup.MuiDialogActions-root {
        .btn {
            height: 50px;
            width: 170px;
            margin-bottom: 10px;
        }

        @include mobile {
            .btn:only-child {
                margin-left: auto;
            }
            flex-direction: row;
            justify-content: space-between;
        }
    }
}
