@import '@styles/styles.scss';

.buyConfirming {
    .mainInfosWrapper {
        background-color: $infosBkColor;
        padding: 40px 30px 0;
        border-radius: 20px;
    }

    // .buyConfirming {
    //     .itemField {
    //         &.realAmount {
    //             .unit,
    //             .numeric {
    //                 color: $secondaryColor;
    //             }
    //         }
    //     }
    // }
}
