@import '@styles/styles.scss';

.btnCopyWrapper {
    .txtCopy {
        display: none;
    }

    .btnCopy {
        &.MuiButton-text {
            width: 30px;
            height: 30px;
            min-width: 26px;
            padding: 0;
            background: #292929 0% 0% no-repeat padding-box;
        }
    }
}
