@import '@styles/styles.scss';

.amountInfosWrapper {
    margin-top: 0;

    .mainInfosItemsWrapper {
        display: flex;
        flex-direction: column;
    }

    .rightInfos {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .realAmount {
            .itemFieldLeft {
                .fieldLabel {
                    font-size: $FONTSIZE_22;
                }

                .numeric {
                    font-size: $FONTSIZE_30;
                }

                .unit {
                    font-size: $FONTSIZE_24;
                }
            }
        }
    }

    .remindMsg {
        &.remindMsgRight {
            text-align: right;
        }
    }

    @include tablet {
        .rightInfos {
            margin-top: 25px;
        }

        .remindMsg {
            &.remindMsgRight {
                text-align: left;
            }
        }
    }
}
