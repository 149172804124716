@mixin itemCircle() {
    border-radius: 50%;
    background-color: $COLOR_WHITE;
    border: 3px solid $secondaryColor;
}

@mixin secondaryGradientVerticalBk() {
    background: transparent linear-gradient(180deg, #80d8ff 0%, #ea80fc 100%) 0%
        0% no-repeat padding-box;
}

@mixin secondaryGradientHorizontalBk() {
    background: transparent linear-gradient(270deg, #80d8ff 0%, #ea80fc 100%) 0%
        0% no-repeat padding-box;
}

@mixin headerHightLightLeft() {
    &:before {
        content: '';
        @include secondaryGradientVerticalBk();
        padding: 2px;
        margin-right: 5px;
    }
}

@mixin headerHighlightBottom() {
    &:after {
        position: absolute;
        content: '';
        right: auto;
        top: auto;
        border: none;
        height: 4px;
        left: 50%;
        bottom: -15px;
        width: 60px;
        margin-left: -30px;
        @include secondaryGradientHorizontalBk();
    }
}
